
  
  /*---------------------------------------------*/
  a {
      font-size: 14px;
      line-height: 1.7;
      color: #666666;
      margin: 0px;
      transition: all 0.4s;
      -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
  }
  
  a:focus {
      outline: none !important;
  }
  
  a:hover {
      text-decoration: none;
    color: #6a7dfe;
    color: -webkit-linear-gradient(to left, #21d4fd, #b721ff);
    color: -o-linear-gradient(to left, #21d4fd, #b721ff);
    color: -moz-linear-gradient(to left, #21d4fd, #b721ff);
    color: linear-gradient(to left, #21d4fd, #b721ff);
  }
  
  
  ul, li {
      margin: 0px;
      list-style-type: none;
  }
  
  
  /*---------------------------------------------*/
  input {
      outline: none;
      border: none;
  }
  
  textarea {
    outline: none;
    border: none;
  }
  
  textarea:focus, input:focus {
    border-color: transparent !important;
  }
  
  input:focus::-webkit-input-placeholder { color:transparent; }
  input:focus:-moz-placeholder { color:transparent; }
  input:focus::-moz-placeholder { color:transparent; }
  input:focus:-ms-input-placeholder { color:transparent; }
  
  textarea:focus::-webkit-input-placeholder { color:transparent; }
  textarea:focus:-moz-placeholder { color:transparent; }
  textarea:focus::-moz-placeholder { color:transparent; }
  textarea:focus:-ms-input-placeholder { color:transparent; }
  
  input::-webkit-input-placeholder { color: #adadad;}
  input:-moz-placeholder { color: #adadad;}
  input::-moz-placeholder { color: #adadad;}
  input:-ms-input-placeholder { color: #adadad;}
  
  textarea::-webkit-input-placeholder { color: #adadad;}
  textarea:-moz-placeholder { color: #adadad;}
  textarea::-moz-placeholder { color: #adadad;}
  textarea:-ms-input-placeholder { color: #adadad;}
  
  /*---------------------------------------------*/
  button {
      outline: none !important;
      border: none;
      background: transparent;
  }
  
  button:hover {
      cursor: pointer;
  }
  
  iframe {
      border: none !important;
  }
  
  
  /*//////////////////////////////////////////////////////////////////
  [ Utility ]*/
  .txt1 {
    font-size: 13px;
    color: #666666;
    line-height: 1.5;
  }
  
  .txt2 {
    font-size: 13px;
    color: #333333;
    line-height: 1.5;
  }
  
  /*//////////////////////////////////////////////////////////////////
  [ login ]*/
  
  .limiter {
    width: 100%;
    margin: 0 auto;
  }
  
  .container-login100 {
    width: 100%;  
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background: #f2f2f2;  
  }
  
  .wrap-login100 {
    width: 500px;
    background: #fff;
    border-radius: 0px;
    overflow: hidden;
    padding: 77px 55px 33px 55px;
  
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  }
  
  
  /*------------------------------------------------------------------
  [ Form ]*/
  
  .login100-form {
    width: 100%;
  }
  
  .login100-form-title {
    display: block;
    font-family: Poppins-Bold;
    font-size: 30px;
    color: #154989;
    line-height: 1.2;
    text-align: center;
  }
  .login100-form-title i {
    font-size: 60px;
  }
  
  /*------------------------------------------------------------------
  [ Input ]*/
  
  .wrap-input100 {
    width: 100%;
    position: relative;
    border-bottom: 2px solid #adadad;
    margin-bottom: 37px;
  }
  
  .input100 {
    font-size: 15px;
    color: #555555;
    line-height: 1.2;
  
    display: block;
    width: 100%;
    height: 45px;
    background: transparent;
    padding: 0 5px;
  }
  
  /*---------------------------------------------*/ 
  .focus-input100 {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: -18px;
    left: 0;
    pointer-events: none;
  }
  
  .focus-input100::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 0;
    height: 2px;
  
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  
    background: #154989;
    background: -webkit-linear-gradient(to left, #154989, #154989);
    background: -o-linear-gradient(to left, #154989, #154989);
    background: -moz-linear-gradient(to left, #154989, #154989);
    background: linear-gradient(to left, #154989, #154989);
  }
  
  .focus-input100::after {
    font-size: 15px;
    color: #999999;
    line-height: 1.2;
  
    content: attr(data-placeholder);
    display: block;
    width: 100%;
    position: absolute;
    top: 16px;
    left: 0px;
    padding-left: 5px;
  
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  .input100:focus + .focus-input100::after {
    top: -3px;
  }
  
  .input100:focus + .focus-input100::before {
    width: 100%;
  }
  
  .has-val.input100 + .focus-input100::after {
    top: -15px;
  }
  
  .has-val.input100 + .focus-input100::before {
    width: 100%;
  }
  
  /*---------------------------------------------*/
  .btn-show-pass {
    font-size: 15px;
    color: #999999;
  
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    padding-right: 5px;
    cursor: pointer;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  }
  
  .btn-show-pass:hover {
    color: #154989;
    color: -webkit-linear-gradient(to left, #154989, #154989);
    color: -o-linear-gradient(to left, #154989, #154989);
    color: -moz-linear-gradient(to left, #154989, #154989);
    color: linear-gradient(to left, #154989, #154989);
  }
  
  .btn-show-pass.active {
    color: #6a7dfe;
    color: -webkit-linear-gradient(to left, #154989, #154989);
    color: -o-linear-gradient(to left, #154989, #154989);
    color: -moz-linear-gradient(to left, #154989, #154989);
    color: linear-gradient(to left, #154989, #154989);
  }
  
  
  
  /*------------------------------------------------------------------
  [ Button ]*/
  .container-login100-form-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 13px;
  }
  
  .wrap-login100-form-btn {
    width: 100%;
    display: block;
    position: relative;
    z-index: 1;
    border-radius: 25px;
    overflow: hidden;
    margin: 0 auto;
  }
  
  .login100-form-bgbtn {
    position: absolute;
    z-index: -1;
    width: 300%;
    height: 100%;
    background: #154989;
    /* background: -webkit-linear-gradient(to right, #21d4fd, #154989, #21d4fd, #154989);
    background: -o-linear-gradient(to right, #21d4fd, #154989, #21d4fd, #154989);
    background: -moz-linear-gradient(to right, #21d4fd, #154989, #21d4fd, #154989);
    background: linear-gradient(to right, #21d4fd, #154989, #21d4fd, #154989); */
    top: 0;
    left: -100%;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  }
  
  .login100-form-btn {
    font-family: Poppins-Medium;
    font-size: 15px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
  
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 40px;
  }
  
  .wrap-login100-form-btn:hover .login100-form-bgbtn {
    left: 0;
  }
  
  
  /*------------------------------------------------------------------
  [ Responsive ]*/
  
  @media (max-width: 576px) {
    .wrap-login100 {
      padding: 77px 15px 33px 15px;
    }
  }
  
  
  
  /*------------------------------------------------------------------
  [ Alert validate ]*/
  
  .validate-input {
    position: relative;
  }
  
  .alert-validate::before {
    content: attr(data-validate);
    position: absolute;
    max-width: 70%;
    background-color: #fff;
    border: 1px solid #c80000;
    border-radius: 2px;
    padding: 4px 25px 4px 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0px;
    pointer-events: none;
    color: #c80000;
    font-size: 13px;
    line-height: 1.4;
    text-align: left;
  
    visibility: hidden;
    opacity: 0;
  
    -webkit-transition: opacity 0.4s;
    -o-transition: opacity 0.4s;
    -moz-transition: opacity 0.4s;
    transition: opacity 0.4s;
  }
  
  .alert-validate::after {
    content: "\f06a";
    font-family: FontAwesome;
    font-size: 16px;
    color: #c80000;
  
    display: block;
    position: absolute;
    background-color: #fff;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 5px;
  }
  
  .alert-validate:hover:before {
    visibility: visible;
    opacity: 1;
  }
  
  @media (max-width: 992px) {
    .alert-validate::before {
      visibility: visible;
      opacity: 1;
    }
  }

  .login--spinner--100 {
      align-content: center;
      vertical-align: middle;
      margin-top: 50px;
      margin-left: 120px;
  }

  .show-password-icon {
    float: right;
    margin-left: -25px;
    margin-top: -25px;
    position: relative;
    z-index: 2;
  }
  
  
  
  
  