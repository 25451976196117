.tr-item-content-container {
    position: relative;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20vh;
    margin-bottom: 10vh;
 }
 
 .tr-item-body {
    position: relative;
    height: 80vh;
    display: flex;
    justify-content:center;
    align-items:center;
 }
 
 .tr-details-body {
    position: relative;
    display: flex;
    flex-direction:column;
    width: 75%;
 }
 
 .tr-details-group-body {
    position: relative;
    display: flex;
    flex-direction:column;
    margin-bottom: 20px;
    background: white;
    padding:20px;
 }
 
 .tr-details-group-title {
    font-size: 24px;
    color: #3e3e3e;
 }
 
 .tr-details-row-body {
    position: relative;
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 16px;
    flex-wrap: wrap;
 }
 
 .tr-details-row-left-body {
   flex: 1;
   margin-right: 100px;
 }
 
 .tr-details-row-right-body {
   flex: 1;
   text-align: right;
 }
 
 .trl-layout {
    position: relative;
    width:100%;
    min-width: 800px;
    min-height: 800px;
    height: auto;
 }
 
 .trl-content-container {
    position: relative;
    width: 100%;
    min-width: 800px;
    min-height: 500px;
    margin-top: 20vh;
    padding: 0;
    background: #fff;
 }
 
 .trans-content-container {
   position: relative;
   margin-left: 10%;
   margin-right: 10%;
   display: flex;
   flex: none;
   flex-direction: column;
   align-items: center;
   background: #fff;
   border-radius: 8px;
   padding-left: 20px;
   padding-right: 20px;
   padding-top: 10px;
   padding-bottom: 20px;
   width: 80%;
   margin-top: 20vh;
   margin-bottom: 10vh;
 }
 
 .trl-select-recipient {
    position: relative;
    margin-top: 30px;
    width: 100%;
    text-align: center;
 }
 
 .trl-first-last-body {
    position: relative;
    display: flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-around;
    width: 30%;
 }
 
 .trans-footer-box-parent {
    position: relative;
    width: 60%;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 20%;
    margin-right: 20%;
    display: flex;
 }
 
 .trans-footer-box1 {
     flex: 1;
 }
 
 .trans-footer-box2 {
   flex: 1;
 }
 
 .trans-footer-box3 {
   flex: 1;
 }
 
 .trans-footer-box4 {
   flex: 1;
 }
 
 .trans-footer-box5 {
   flex: 1;
 }
 
 .tr-error-parent {
   display: flex;
   flex-direction: column;
   align-items: center;
 }
 
 .tr-error-title {
   text-align: center;
   margin-top: 16px;
   margin-bottom: 16px;
   width: 100%;
 }
 
 .tr-error-tree {
   width: 80%;
 
 }
 
 .tr-error {
   text-align: center;
   margin-bottom: 8px;
   width: 100%;
 }
 
 
 .trans-body {
    position: relative;
    width: 100%;
    min-height: 500px;
    height: 100%;
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
 }
 
 @media screen and (max-height: 850px) {
    .trl-container { height: 100%; }
 }
 
 
 .trans-layout {
   position: relative;
   width: 100%;
   background: #f2f2f2;
 }
 
 .ant-spin-nested-loading {
   width: 100%;
 }
 
 .ant-table-tbody > tr:hover > td {
   background: #f2f2f2;
 }
 
 .ant-btn-primary {
   height: 46px;
   font-size: 16px;
   background-color: #154989;
   border: 1px solid #154989;
   max-width: 200px;
 }
 
 .tn-pagination-box {
     position: relative;
     display: flex;
     margin-top: 2vh;
     width: 100%;
     margin-bottom: 10px;
     justify-content: space-around;
 
 }
 
 .tn-pagination-box-right {
     flex: 1;
 }
 
 .tn-pagination-box-middle {
     flex: 4;
     text-align: center;
 }
 
 .tn-pagination-box-left {
     flex: 1;
 }
 
 .tn-pagination-box-item {
     margin-left: 0px;
     margin-right: 5px;
     height: 30px;
     min-width: 100px;
     font-size: 16px;
     color: #154989;
     border: 1px solid #154989;
     border-radius: 0px;
 
 }
 
 .ant-table {
   color: #0a0a0a;
   font-weight: 500;
 }
 
 .table-even-row {
   background-color: #f0f8ff;
 }
 
 .table-header-row {
   background-color: #3e3e3e;
 }

 .ant-table-pagination {
   width: 30%;
   align-items: right;
   margin-left: 68%;
   text-align: right;

 }

 .ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: Arial;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.ant-pagination-item-active {
  font-weight: 500;
  border-color: #1890ff;
}

.ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  color: rgba(0,0,0,0.65);
  font-family: Arial;
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: all .3s;
  transition: all .3s;
}